import React from "react";

export const BookIcon = ({
  size,
  className,
}: {
  size: string;
  className?: string;
}) => (
  <svg
    className={className ? className : ""}
    style={{ fill: "currentColor" }}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M21,3h-7c-0.771,0-1.468,0.301-2,0.78C11.468,3.301,10.771,3,10,3H3C2.447,3,2,3.448,2,4v15c0,0.553,0.447,1,1,1h5.758 c0.526,0,1.042,0.214,1.414,0.586l1.121,1.121c0.009,0.009,0.021,0.012,0.03,0.021c0.086,0.079,0.182,0.149,0.294,0.196 c0.001,0,0.001,0,0.002,0C11.74,21.974,11.869,22,12,22s0.26-0.026,0.381-0.076c0.001,0,0.001,0,0.002,0 c0.112-0.047,0.208-0.117,0.294-0.196c0.009-0.009,0.021-0.012,0.03-0.021l1.121-1.121C14.2,20.214,14.716,20,15.242,20H21 c0.553,0,1-0.447,1-1V4C22,3.448,21.553,3,21,3z M8.758,18H4V5h6c0.552,0,1,0.449,1,1v12.689C10.342,18.246,9.557,18,8.758,18z M20,18h-4.758c-0.799,0-1.584,0.246-2.242,0.689V6c0-0.551,0.448-1,1-1h6V18z" />
  </svg>
);
